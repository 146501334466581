
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import {
  Orderline,
  OrderlineDetails,
  OrderlinePurchaseStateTypes
} from '../../store/models/OrderlineModel';
import { OrdersService } from '../../services/orders-service';
import { AddressService } from '@/services/address-service';
import { ConversionHelper } from '../../helpers/ConversionHelper';
import { IsInLocalStorage, GetFromLocalStorage } from '@/helpers/StorageHelper';
import DefaultLayout from '@/components/DefaultLayout.vue';
import J1lImg from '@/components/J1lImg.vue';
import OrderlineCard from '@/components/cardComponents/OrderlineCard.vue';
import OrderEditPo from '@/components/OrderEditPo.vue';
import OrderEditShippingAddress from '@/components/OrderEditShippingAddress.vue';
import OrderViewAddressChange from '@/components/OrderViewAddressChange.vue';
import OrderSummary from '@/components/OrderSummary.vue';
import { Item } from '@/store/models/ItemModel';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { StringHelper } from '@/helpers/StringHelper';
import { ConstValues } from '@shared/ConstValues';
import { Confirm } from '@/helpers/AuthHelper';
import ItemService from '@/services/item-service';
@Component({
  components: {
    DefaultLayout,
    J1lImg,
    OrderlineCard,
    OrderSummary,
    OrderEditPo,
    OrderEditShippingAddress,
    OrderViewAddressChange
  },
  props: {
    isReorder: Boolean
  }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
    OrderSummary: HTMLFormElement;
    form: HTMLFormElement;
    quantityField: HTMLFormElement;
    itemSelect: HTMLFormElement;
    OrderEditPo: HTMLFormElement;
    OrderEditShippingAddress: HTMLFormElement;
    OrderViewAddressChange: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Getter('getCurrentOrder', { namespace: 'orderlines' })
  currentOrder!: OrderlineDetails[];
  @Getter('getItemPartNumber', { namespace: 'items' })
  getPartNumbers: any;
  @Action('ChangePO', { namespace: 'orderlines' })
  changePOInStore: any;
  @Action('RemoveOrdersByPO', { namespace: 'orderlines' })
  removeOrdersByPO: any;
  @Action('ClearCurrentOrder', { namespace: 'orderlines' })
  clearCurrentOrder!: any;
  @Mutation('setCurrentOrder', { namespace: 'orderlines' })
  setCurrentOrderInStore: any;
  @Action('getCurrentCustomer', { namespace: 'customer' })
  getCurrentCustomerStore: any;
  @Getter('getCustomer', { namespace: 'customer' })
  currentCustomer: any;
  @Getter('getLoadingState', { namespace: 'items' })
  getLoadingState!: boolean;
  /* Watchers */
  @Watch('currentOrder')
  onCurrentOrderChange() {
    this.orderlines = this.currentOrder;
  }
  @Watch('search')
  searching(val: string) {
    if (val) this.refreshImageForSearch();
  }

  @Watch('po')
  onPoChanged() {
    if (!this.$route.params.po && this.po) {
      this.$router.push({
        name: 'OrderView',
        params: { po: this.po }
      });
    }
  }
  @Watch('$route.params.po')
  async onRoutePoChanged() {
    this.handlePoChange();
  }
  @Watch('selectedItem', { deep: true })
  onSelectedItemChange(val: any) {
    if (val) {
      this.refreshImageForSearch();
      setTimeout(() => {
        this.$refs.quantityField.focus();
      }, 200);
    }
  }
  /* Data */
  isImageRendered: boolean = false;
  customerNote: string = '';
  isGettingOrderDetails: boolean = false;
  isAddingNewOrderline: boolean = false;
  isTextInputValid: boolean = false;
  isReordering: boolean = false;
  shouldUseNavigationGuard: boolean = true;
  search: string = '';
  selectedItem: {
    PartNumber: string;
    Page: number;
    Description: string;
    Id: string;
  } | null = null;
  refreshImg: boolean = true;
  isCancellingOrder: boolean = false;
  isSavingOrder: boolean = false;
  orderlines: Orderline[] | OrderlineDetails[] = [];
  shippingAddresses: any[] = [];
  selectedShippingAddress: any = null;
  selectedOrderline: Orderline | null = null;
  confirmationDuplicateText: string =
    'The item you are trying to add is already in the order, are you sure you want to add a duplicate?';
  confirmationDuplicateTitle: string = 'Are you sure?';
  confirmationCancelOrderTitle: string = 'Cancel Order';
  confirmationCancelOrderText: string = 'Are you sure you want to cancel this order?';
  po: string = '';
  quantity: number | null = null;
  navigationGuard: any;
  /* Methods */
  beforeRouteLeave(to: any, from: any, next: any) {
    if (!this.shouldUseNavigationGuard) {
      next();
      return;
    }
    if (
      from.name &&
      from.name.startsWith('OrderView') &&
      to.name &&
      !(to.name.startsWith('OrderView') || to.name.startsWith('orderConfirmation')) &&
      this.orderlines[0]
    ) {
      if (
        window.confirm(
          'Are you sure you want to leave? Your Order has not yet been saved and you will lose your progress'
        )
      ) {
        next();
      }
    } else {
      next();
    }
  }

  bypassNavigationGuard() {
    this.shouldUseNavigationGuard = false;
  }
  isMobile() {
    return this.$refs.defaultActions ? this.$refs.defaultActions.shouldUseMobile : false;
  }
  isCartOrder() {
    return this.$route.query.isCartOrder === 'true';
  }
  goBack() {
    this.$router.push({ name: 'Orders' });
  }
  async getOrderDetails(po: string) {
    this.isGettingOrderDetails = true;
    try {
      if (
        this.currentOrder[0] &&
        this.$route.params.po &&
        this.currentOrder[0].PO === this.$route.params.po
      ) {
        this.orderlines = this.currentOrder;
      } else if (IsInLocalStorage('currentOrder', 'PO', po, OrderlineDetails)) {
        this.orderlines = GetFromLocalStorage('currentOrder', Array<OrderlineDetails>());
        this.setCurrentOrderInStore(this.orderlines);
      } else {
        await this.getOrderFull();
      }
      if (this.orderlines[0]) {
        this.$refs.OrderSummary.GeneratePricingData();
      }
    } catch (err) {
      SetSnackBar(
        'There was an error loading your order, please refresh your page, or contact techincal support if error persists'
      );
      console.log(err);
    }
    (this.orderlines as OrderlineDetails[]).sort(ol => ol.OrderLineNumber).reverse();
    this.isGettingOrderDetails = false;
  }
  async getOrderFull() {
    const { data } = await OrdersService.GetOrderFull(
      this.$route.params.po,
      0,
      this.isCartOrder()
    );
    if (data) {
      this.orderlines = data.map((ol: any) => (ol = new OrderlineDetails(ol)));
      this.setCurrentOrderInStore(this.orderlines);
    }
  }
  filterObject(item: any, queryText: string, itemText: string) {
    return (
      item.PartNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
      item.Description.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }
  refreshItem(val: { orderlineId: string; item: Item }) {
    let orderlineToRefresh = this.orderlines.find(o => o.Id === val.orderlineId);
    if (orderlineToRefresh) orderlineToRefresh.Item = val.item;
  }
  refreshImageForSearch() {
    this.refreshImg = false;
    setTimeout(() => (this.refreshImg = true), 0.5);
  }

  async getShippingAddresses() {
    try {
      const response = await AddressService.GetShippingAddressesFull();
      this.shippingAddresses = response.data;
      if (this.shippingAddresses[0]) {
        let defaultAddress = this.shippingAddresses.find(a => a.IsDefault);
        this.selectedShippingAddress = defaultAddress
          ? defaultAddress
          : this.shippingAddresses[this.shippingAddresses.length - 1];
      }
    } catch (err) {
      SetSnackBar(
        'There was an error loading your shipping addresses, please refresh your page, or contact techincal support if error persists'
      );
      console.log(err);
    }
  }
  GoToDuplicateOrder(po: string) {
    this.$router.push({
      name: 'OrderView',
      params: { po: po }
    });
    location.reload();
  }
  checkForDuplicates() {
    const duplicate = this.orderlines.find(ol => ol.Item.Id == this.selectedItem!.Id);
    if (duplicate) {
      Confirm(
        () => {
          this.addOrderline();
        },
        'Are you sure?',
        'The item you are trying to add is already in the order, are you sure you want to add a duplicate?'
      );
    } else {
      this.addOrderline();
    }
  }
  validate() {
    return (
      this.$refs.form.validate() &&
      this.selectedShippingAddress &&
      this.selectedItem &&
      this.$refs.OrderSummary.customerPO
    );
  }
  formatStringLength(value: string, length: number) {
    if (value) {
      return `${value.substring(0, length)}${value.length >= length ? '...' : ''}`;
    }
    return '';
  }
  async addOrderline() {
    this.$refs.itemSelect.focus();
    if (this.validate()) {
      try {
        let quantity = this.quantity!.toString().replace(/\W/g, '');
        this.isAddingNewOrderline = true;
        const model = ConversionHelper.generateNewOrderlineModel(
          this.selectedItem!.Id,
          parseInt(quantity),
          this.selectedShippingAddress.Id,
          this.$refs.OrderSummary.customerPO
        );
        if (!this.currentCustomer) {
          await this.getCurrentCustomerStore();
        }
        model.RollDirectionId = this.selectedShippingAddress.IsCustomerPickup
          ? this.currentCustomer.Options.RollDirection.Id
          : this.selectedShippingAddress.Options.RollDirectionId;
        const response = await OrdersService.GenerateOrderlineDefaults(model);
        if (response.data) {
          const newOrderline = new OrderlineDetails(response.data);
          newOrderline.OrderLineNumber =
            (this.orderlines.length >= 1
              ? Math.max.apply(
                  Math,
                  (this.orderlines as OrderlineDetails[]).map(ol => {
                    return ol.OrderLineNumber;
                  })
                )
              : 0) + 1;
          newOrderline.OrderlinePurchaseStateType = OrderlinePurchaseStateTypes.Create;
          const { data } = await ItemService.ShouldGenerateRetention(model.ItemId);
          if (data) {
            model.ShouldGenerateRetention = true;
            var { data: newRetention } = await OrdersService.GenerateOrderlineDefaults(
              model
            );
            newRetention = new OrderlineDetails(newRetention);
            newRetention.OrderlinePurchaseStateType = OrderlinePurchaseStateTypes.Create;
            var newOrderlines = [
              newOrderline,
              newRetention,
              ...(this.orderlines as OrderlineDetails[])
            ];
          } else {
            newOrderlines = [newOrderline, ...(this.orderlines as OrderlineDetails[])];
          }
          this.setCurrentOrderInStore(newOrderlines);
          this.$refs.OrderSummary.GeneratePricingData();
          this.quantity = null;
          this.selectedItem = null;
        }
      } catch (err) {
        SetSnackBar(
          'Something went wrong while adding your order, please refresh your page, or contact techincal support if error persists'
        );
        console.log(err);
      }
      this.isAddingNewOrderline = false;
    }
  }
  deleteOrderline(orderlineId: any) {
    let id: string = orderlineId.Id || orderlineId;
    this.orderlines = this.orderlines.filter(ol => ol.Id !== id);
    this.setCurrentOrderInStore(this.orderlines);
    SetSnackBar('Successfully removed this orderline from the system');
    this.$refs.OrderSummary.GeneratePricingData();
  }
  async updateOrderline(orderline: OrderlineDetails) {
    let oldOrderlineIndex = this.orderlines.findIndex(ol => ol.Id === orderline.Id);
    if (oldOrderlineIndex > -1) {
      SetSnackBar('updating your order...');
      this.setOrderlinePurchaseStateType(orderline);
      const model = ConversionHelper.convertOrderlineToAddModel(orderline);
      const response = await OrdersService.GenerateOrderlineDefaults(model);
      if (response.data) {
        const newOrderline = new OrderlineDetails(response.data);
        newOrderline.CustomerNote = orderline.CustomerNote;
        this.setOrderlinePurchaseStateType(this.orderlines[oldOrderlineIndex]);
        newOrderline.OrderlinePurchaseStateType = this.orderlines[
          oldOrderlineIndex
        ].OrderlinePurchaseStateType;
        this.orderlines[oldOrderlineIndex] = newOrderline;
        this.setCurrentOrderInStore(this.orderlines);
        this.$refs.OrderSummary.GeneratePricingData();
      }
      this.orderlines[oldOrderlineIndex] = orderline;
      this.setCurrentOrderInStore(this.orderlines);
      SetSnackBar('Updated Orderline successfully');
    }
  }
  async cancelOrder(didAccept: boolean) {
    this.isCancellingOrder = true;
    if (didAccept) {
      try {
        await OrdersService.CancelOrder(this.po, this.isCartOrder());
        this.clearCurrentOrder();
        SetSnackBar('Order Cancelled');
        await this.removeOrdersByPO(this.po);
        this.$router.push({ name: 'Orders' });
      } catch (err) {
        if (err.response.status === 400) {
          SetSnackBar(
            'Cannot cancel order that is already in production. Please contact info@just1label.com for more information'
          );
        }
      }
    }
    this.isCancellingOrder = false;
  }
  openEditPoDialog() {
    this.$refs.OrderEditPo.showDialog();
  }
  openEditAddressDialog() {
    this.$refs.OrderViewAddressChange.showDialog();
  }
  changeAddress(val: any) {
    if (val.ShouldChangeAllAddresses) {
      for (let ol of this.orderlines) {
        ol.Address = val;
        ol.RollDirection.Id = val.Options.RollDirectionId;
        if (ol instanceof OrderlineDetails) {
          ol.ShippingAddress = val;
        }
        this.setOrderlinePurchaseStateType(ol);
      }
    }
    if (val.orderId) {
      let orderline = this.orderlines.find(ol => ol.Id == val.orderId);
      if (orderline) {
        orderline.Address = val.shippingAddress;
        orderline.RollDirection.Id = val.shippingAddress.Options.RollDirectionId;
        if (orderline instanceof OrderlineDetails) {
          orderline.ShippingAddress = val.shippingAddress;
        }
        this.setOrderlinePurchaseStateType(orderline);
      }
      if (orderline || val.ShouldChangeAllAddresses) {
        this.setCurrentOrderInStore(this.orderlines);
      }
    } else {
      this.selectedShippingAddress = val;
    }
  }
  async saveForLater() {
    this.isSavingOrder = true;
    await this.$refs.OrderSummary.saveForLater();
  }
  async setPO(val: string) {
    (this.orderlines as Orderline[]).map((ol: Orderline) => (ol.PO = val));
    if (this.po !== val) {
      this.po = val;
    }
  }
  async createPO() {
    const response = await OrdersService.CreateDefaultPo();
    this.po = response.data;
    this.setPO(this.po);
  }
  canSeeOrderline(Orderline: Orderline) {
    return Orderline.IsVisible;
  }
  setOrderlinePurchaseStateType(Orderline: Orderline) {
    switch (Orderline.OrderlinePurchaseStateType) {
      case OrderlinePurchaseStateTypes.DoNothing:
      case OrderlinePurchaseStateTypes.Edit:
        Orderline.OrderlinePurchaseStateType = OrderlinePurchaseStateTypes.Edit;
        return;
      case OrderlinePurchaseStateTypes.Create:
      case OrderlinePurchaseStateTypes.Delete:
        return;
    }
  }
  handleQuantityInput(newValue: any) {
    this.quantity = parseInt(newValue.toString().replace(/\D/g, ''));
  }

  /* Computed*/
  get canAddToOrder() {
    return (
      this.selectedItem &&
      this.selectedShippingAddress &&
      this.quantity! > 0 &&
      this.po &&
      this.isTextInputValid
    );
  }
  get formattedQuantity() {
    return StringHelper.formatQuantity(this.quantity);
  }
  /* Loaders */
  async reCreateOrder(orderlineId: string) {
    this.setCurrentOrderInStore([]);
    this.orderlines = [];
    this.isReordering = true;
    await this.getShippingAddresses();
    const response = await OrdersService.GetCustomerOrderByOrderLineId(orderlineId);
    if (response.data && response.data.Orderlines) {
      let orderlines = response.data.Orderlines.map((d: any) => new OrderlineDetails(d));
      var model = [];
      for (let orderline of orderlines) {
        let address = this.shippingAddresses.find(a => a.Id === orderline.Address.Id);
        if (!address) {
          address = this.shippingAddresses[0];
        }
        model.push(
          ConversionHelper.generateNewOrderlineModel(
            orderline.Item.Id,
            orderline.Quantity,
            address.Id,
            this.$route.params.po
          )
        );
      }
      const orderResponse = await OrdersService.RecreateOrder(model);
      let returnValue = orderResponse.data.map((ol: any) => new OrderlineDetails(ol));
      returnValue.forEach(
        (r: OrderlineDetails) =>
          (r.OrderlinePurchaseStateType = OrderlinePurchaseStateTypes.Create)
      );
      this.setCurrentOrderInStore(returnValue);
      this.$refs.OrderSummary.GeneratePricingData();
    }
    this.isReordering = false;
  }
  async handlePoChange() {
    this.po = this.$route.params.po;
    if (!this.po) {
      this.setCurrentOrderInStore([]);
      await this.createPO();
    } else {
      if (this.$route.params.orderlineId) {
        this.reCreateOrder(this.$route.params.orderlineId);
      } else {
        await this.getOrderDetails(this.$route.params.po);
        this.setCurrentOrderInStore(this.orderlines);
      }
    }
  }
  /* Mounted */
  async mounted() {
    this.handlePoChange();
    await this.getShippingAddresses();
    if (
      this.selectedShippingAddress.Options.RollDirectionId === 10 &&
      this.selectedShippingAddress.Id !== ConstValues.CUSTOMER_PICKUP_ADDRESS_ID
    ) {
      this.$refs.OrderViewAddressChange.showDialog();
    }
  }
  /* Created */
  created() {
    this.po = this.$route.params.po;
  }
}
